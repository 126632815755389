

  import { computed, ref } from 'vue'
  import moment from 'moment';
  import {FilterMatchMode, FilterOperator} from "primevue/api";
  import router from "@/router";
  import {useToast} from "primevue/usetoast";
  import {useStore} from "vuex";
  import {RegisterService} from "@/models/registerService";
  import ZaloRepository from "@/services/ZaloRepository";
  import {ListService} from "@/models/listService";
  import {useRouter} from "vue-router";
  import {NghiPhepDetail} from "@/models/nghiPhepDetail";
  import zaloRepository from "@/services/ZaloRepository";
  import {ThongKeDiemDanh} from "@/models/thongKeDiemDanh";

  export default {
    setup() {
      const toast = useToast();
      const store = useStore();
      const date = ref();
      const service = ref("");
      const router = useRouter();
      const kind = ref(0);
      const listThongKeDiemDanh = ref([] as ThongKeDiemDanh[]);

      const selectCalendar = () => {
        if((date.value != null))
        {
          listThongKeDiemDanh.value = [];
          zaloRepository.getListThongKeDiemDanh(date.value/1000)
              .then((response) => {
                listThongKeDiemDanh.value = response.data;
                console.log("@@@@@@@@@################ list: " + JSON.stringify(listThongKeDiemDanh.value));
              })
              .catch(err => {
                toast.add({
                  severity: 'error',
                  summary: 'Lỗi',
                  detail:err.response.data,
                  life: 2500
                })})
        }
      };

      //selectCalendar();

      const clearCalendar = () => {
        listThongKeDiemDanh.value = [];
      }

      const formatDateTime = (date) => {
        if((date == null)||(date == ''))
        {
          return "chưa có thông tin"
        }
        else
        return moment(String(date)).format('HH:mm DD/MM/YYYY');
      };


      const validDate = (registerService: RegisterService) => {
        return registerService.timeConfirm;
      }

      const clearDate = () => {
        console.log("@@@@@@@@@################ clearDate");
        date.value = new Date();
      }

      const status = (id, xn) => {
        if(xn) return "Cho phép nghỉ";
        else {
          if(id > 0) return "Không cho phép nghỉ";
          else return "Chưa xác nhận";
        }
      }
      const filters = ref({
        'global': {value: null, matchMode: FilterMatchMode.CONTAINS}
      });

      const clearFilter = () => {
        initFilters();
      };

      const initFilters = () => {
        filters.value = {
          'global': {value: null, matchMode: FilterMatchMode.CONTAINS},
        }
      };

      const checkInMorningCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkInMorningImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkin buổi sáng: " + id;
      })

      const checkOutMorningCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkOutMorningImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkout buổi sáng: " + id;
      })

      const checkInAfternoonCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkInAfternoonImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkin buổi chiều: " + id;
      })

      const checkOutAfternoonCount = computed(() => {
        var id = 0;
        listThongKeDiemDanh.value.forEach(x => {
          if(x.checkOutAfternoonImage != null)
          {
            console.log("@@@@@@@@@################ null: ");
            id = id + 1;
          }
        })
        console.log("@@@@@@@@@################ id: " + id);
        return "Checkout buổi chiều: " + id;
      })

      return {
        formatDateTime,
        listThongKeDiemDanh,
        date,
        selectCalendar,
        clearCalendar,
        service,
        validDate,
        kind,
        clearDate,
        status,
        clearFilter,
        filters,
        checkInMorningCount,
        checkOutMorningCount,
        checkInAfternoonCount,
        checkOutAfternoonCount,
      }
    }

  }
