
import {computed, ref} from 'vue'
import { useStore } from 'vuex'
import { useToast } from "primevue/usetoast";
import router from "@/router";

export default {
  props: {
    isLoggedIn: {
      type: Boolean,
      required: true
    }
  },
  setup(props) {
    const store = useStore();
    const toast = useToast();
    const appMenu = computed(() => {
      const menu = ref([
        {
          label:'Gởi tin nhắn',
          icon:'pi pi-fw pi-send',
          to: '/sendmessage'
        },
        {
          label:'Đăng hình ảnh',
          icon:'pi pi-fw pi-upload',
          to: '/uploadImage'
        },
        {
          label:'Thêm nhóm',
          icon:'pi pi-fw pi-plus-circle',
          to: '/addGroup'
        },
        {
          label:'Nhân viên hỗ trợ đặt lịch khám',
          icon:'pi pi-fw pi-comments',
          to: '/addManagerBookingPage'
        },
        {
          label:'Danh sách đăng ký khám bệnh',
          icon:'pi pi-fw pi-list',
          to: '/listRegisterService'
        },
        {
          label:'Nhân viên nhận báo cáo',
          icon:'pi pi-fw pi-bars',
          to: '/addReceiverReportPage'
        },
        {
          label:'Thêm nhân viên hỗ trợ',
          icon:'pi pi-fw pi-user-plus',
          to: '/addemployee'
        },
        {
          label:'Danh sách nhân viên',
          icon:'pi pi-fw pi-users',
          to: '/showlistemployee'
        },
        {
          label:'Quản lý nhóm',
          icon:'pi pi-fw pi-users',
          to: '/managergroup'
        },
        {
          label:'Quản lý nghỉ phép',
          icon:'pi pi-fw pi-list',
          to: '/showlistdayoff'
        },
        {
          label:'Nhận thông báo checkin',
          icon:'pi pi-fw pi-map-marker',
          to: '/addManagerCheckinPage'
        },
        {
          label:'Quản lý checkin',
          icon:'pi pi-fw pi-list pi-map-marker',
          to: '/showListCheckIn'
        },
        {
          label:'Quản lý xe',
          icon:'pi pi-fw pi-car',
          to: '/carPage'
        },
      ]);
      const menuDefault = ref([
        {
          label:'Quản lý nghỉ phép',
          icon:'pi pi-fw pi-list',
          to: '/showlistdayoff'
        }
      ]);
      if(!!store.state.token)
        return menu.value;
      else
        return menuDefault.value;
    });
    const logout = () => {
      store.dispatch('clearToken');
      store.dispatch('clearPermission');
      router.push({
        name: 'login',
      });
      toast.add({
        severity:'success',
        summary: 'Đăng xuất',
        detail:'Đã xóa thông tin đăng nhập thành công',
        life: 1000
      });
    }
    const gotoLoginPage = () => {
      router.push({
        name: 'login',
      });
    }

    return {
      appMenu,
      logout,
      gotoLoginPage
    }
  }
}
